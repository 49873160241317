export const BSS_ROLES = {
  admin: 0,
  reseller: 1
}

export const LOG_ACTIONS = {
  deleteApiKey: "E-FAKTURE: brisanje API kljuca",
  createApiKey: "E-FAKTURE: dodavanje API kljuca",
  addNewInvoice: "Dodavanje novog racuna",
  blockPayment: "Blokiranje uplate",
  removePaymentAllocation: "Uklanjanje dodele placanja",
  uploadPayments: "Upload placanja",
  cancelInvoice: "Otkazivanje racuna",
  deleteInvoice: "Brisanje racuna",
  business: "Promena podataka firme",
  addUser: "Dodavanje korisnika",
  deleteUser: "Brisanje korisnika",
  assignPaymentToUser: "Dodela uplate korisniku",
  resolvePayment: "Resavanje placanja",
  passwordReset: "Resetovanje lozinke",
  deleteCertificate: "Brisanje sertifikata",
  temporaryExtendLicense: "Privremeno produzenje licence",
  licenseCount: "Promena broja licence",
  eFactureLicense: "E-FAKTURE dodavanje licence",
  resetTermsOfUse: "Resetovanje uslova korišćenja",
}

export const ORDERS_STATUS = {
  created: "created",
  accepted: "accepted",
  rejected: "rejected",
  finished: "finished"
}
export const PAYMENT_STATUS = {
  0: 'Successful',
  1: 'No valid ref number',
  2: 'No valid ref number, but suspicious amount',
  3: 'User finance data not found with given contract ID'
}
export const CLIENT_PREVIEW_TAB = [
  "Operateri", "Proizvodi", "Lokacije", "Licence", "Podešavanja", "Kontakti", "Računi","Kartica korisnika","Kartica naloga", "Aktivnost"
];

export const TENANT_REPORT_TAB = [
  "Finansijski izveštaj", "Izveštaj aktivacija"
];

export const INVOICE_BASE_URL = process.env.REACT_APP_FIREBASE_BASE_URL + "/download/pdf?refNumber=";

export const ALL_LICENSE = {
  Esir: 0,
  Lpfr: 1,
  eFacture: 2,
  windowsLPFR: 3
};

export const UNIT_ONLY_STRINGS = ["kom", "l", "kg", "m2", "h", "tona", "par", "m3", "metar", "sum"];

export const FILE_NAME_INCLUDE = "nochain.CERiKEY";
export const CERT_START = "-----BEGIN CERTIFICATE-----";
export const CERT_FINISH = "-----END CERTIFICATE-----";
export const KEY_START = "-----BEGIN RSA PRIVATE KEY-----";
export const KEY_FINISH = "-----END RSA PRIVATE KEY-----";
